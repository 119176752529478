import React, { useState } from 'react';
import ResponseTimeCard from './ResponseTimeCard';
import ContactCard from './ContactCard';
import FunnelCard from './FunnelCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import SocialMediaCard from './SocialMediaCard';
import IoLeadsCount from './IoLeadsCount';
import IoOtherDetails from './IoOtherDetails';
import DatePicker from 'react-datepicker';
import { fetchIoStatsForGivenDates } from '../apis/ioServiceApi';
import { notify } from "../utilComponents/Notification";
import IoLeadDistribution from './IoLeadDistributionCard';
import RentalDetailsCard from './RentailsCard';
import WorkflowCard from './WorkflowCard';
import Ads from './Ads';
import BillingCard from './BillingCard';

const LocationData = (props) => {
    const businessDetails = props.locationDetails;

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [ioStatsData, setIoStatsData] = useState(false);

    const handleApply = async () => {
        setLoading(true);
        try {
            const ioStatsData = await fetchIoStatsForGivenDates(businessDetails.id, startDate, endDate);
            setIoStatsData(ioStatsData);
        } catch (error) {
            notify('error', 'Error', error.message);
        }
        setLoading(false);
    }

    const extractDomain = (url) => {
        try {
            const domain = url.includes('www') ? (new URL(url)).hostname.replace('www.', '') : url;
            return domain;
        } catch (error) {
            return url;
        }
    };

    return (
        <div className="page-container">
            <section className="location-details-section">
                <div className="container">
                    {/* First Row */}
                    <div className="location-row">
                        {/* Logo Section */}
                        <div className="location-col-lg-4 location-card">
                            <img src={businessDetails.logoUrl} alt="avatar" className="avatar" />
                            <p className="text-muted mb-1">{businessDetails.name}</p>
                            <p className="text-muted mb-1">{businessDetails.address}</p>
                            <p className="text-muted mb-1">{businessDetails.city}, {businessDetails.state}, {businessDetails.country}</p>
                            {businessDetails.social.googlePlacesId && (
                                <a className="gplace-icon-link" href={`https://www.google.com/maps/place/?q=place_id:${businessDetails.social.googlePlacesId}`} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} className="gplace-icon" />
                                </a>
                            )}
                        </div>

                        {/* Basic Details Section */}
                        <div className="location-col-lg-4 location-card">
                            <h2 className="card-heading">Basic Details</h2>
                            <hr className="card-separator" />
                            <div className='location-basic-details'>
                                <p><strong>Name:</strong> {businessDetails.firstName} {businessDetails.lastName}</p>
                                <p><strong>Email:</strong> {businessDetails.email}</p>
                                <p><strong>Phone:</strong> {businessDetails.phone}</p>
                                <p><strong>Timezone:</strong> {businessDetails.timezone}</p>
                            </div>
                        </div>

                        {/* Social Links Section */}
                        <div className="location-col-lg-4 location-card">
                            <h2 className="card-heading">Social Links</h2>
                            <hr className="card-separator" />
                            <ul className="social-links-list">
                                <li>
                                    <FontAwesomeIcon icon={faGlobe} className="icon" />
                                    <a href={businessDetails.website} target="_blank" rel="noopener noreferrer">
                                        {businessDetails.website ? extractDomain(businessDetails.website) : 'N/A'}
                                    </a>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faFacebook} className="icon" style={{ color: '#3b5998' }} />
                                    <a href={businessDetails.social.facebookUrl} target="_blank" rel="noopener noreferrer">
                                        {businessDetails.social.facebookUrl ? extractDomain(businessDetails.social.facebookUrl) : 'N/A'}
                                    </a>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faInstagram} className="icon" style={{ color: '#cd486b' }} />
                                    <a href={businessDetails.social.instagram} target="_blank" rel="noopener noreferrer">
                                        {businessDetails.social.instagram ? extractDomain(businessDetails.social.instagram) : 'N/A'}
                                    </a>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faYoutube} className="icon" style={{ color: '#ff0000' }} />
                                    <a href={businessDetails.social.youtube} target="_blank" rel="noopener noreferrer">
                                        {businessDetails.social.youtube ? extractDomain(businessDetails.social.youtube) : 'N/A'}
                                    </a>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faLinkedin} className="icon" style={{ color: '#0077b5' }} />
                                    <a href={businessDetails.social.linkedIn} target="_blank" rel="noopener noreferrer">
                                        {businessDetails.social.linkedIn ? extractDomain(businessDetails.social.linkedIn) : 'N/A'}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Second Row */}
                    <div className="location-row">
                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Social Media</h2>
                            <hr className="card-separator  workflow-card-separator" />
                            <SocialMediaCard locationId={businessDetails.id} />
                        </div>
                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Contacts Count</h2>
                            <hr className="card-separator workflow-card-separator" />
                            <ContactCard locationId={businessDetails.id} />
                        </div>
                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Funnel Data</h2>
                            <hr className="card-separator workflow-card-separator" />
                            <FunnelCard locationId={businessDetails.id} />
                        </div>
                    </div>
                    {/* Third Row for booking software details */}
                    <div className="location-row">
                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Response Time</h2>
                            <hr className="card-separator response-time-card-separator" />
                            <ResponseTimeCard locationId={businessDetails.id} />
                        </div>

                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Rental Items</h2>
                            <hr className="card-separator rental-item-separator" />
                            <RentalDetailsCard locationId={businessDetails.id} />
                        </div>
                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Workflow Count</h2>
                            <hr className="card-separator workflow-card-separator" />
                            <WorkflowCard locationId={businessDetails.id} />
                        </div>
                    </div>
                    <div className="location-row">
                        <div className="location-col-md-6 location-card">
                            <h2 className="card-heading">Google Ads</h2>
                            <hr className="card-separator response-time-card-separator" />
                            <Ads locationId={businessDetails.id} />
                        </div>

                        <div className="location-col-md-6 location-card">
                            <h2 className="card-heading">Billing Data</h2>
                            <hr className="card-separator rental-item-separator" />
                            <BillingCard locationId={businessDetails.id} />
                        </div>
                        {/* <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Workflow Count</h2>
                            <hr className="card-separator workflow-card-separator" />
                            <WorkflowCard locationId={businessDetails.id} />
                        </div> */}
                    </div>
                    {/* Row for data select */}
                    <div className='location-row'>
                        <div className="date-selector-container">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                className="date-input"
                            />
                            <span className="date-arrow">→</span>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dateFormat="yyyy-MM-dd"
                                className="date-input"
                            />
                            <button disabled={loading} className="apply-button" onClick={handleApply}>
                                Apply
                            </button>
                        </div>
                    </div>
                    {/* booking software details */}
                    {ioStatsData && <div className='location-row'>
                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">IO leads</h2>
                            <hr className="card-separator io-leads-count-separator" />
                            <IoLeadsCount ioStats={ioStatsData} />
                        </div>
                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Other Details</h2>
                            <hr className="card-separator io-other-details-separator" />
                            <IoOtherDetails ioStats={ioStatsData} />
                        </div>
                        <div className="location-col-md-4 location-card">
                            <h2 className="card-heading">Leads Distribution</h2>
                            <hr className="card-separator io-leadDistribution-saparator" />
                            <IoLeadDistribution ioStats={ioStatsData} />
                        </div>
                    </div>}
                </div>
            </section>
        </div>
    );
};

export default LocationData;
