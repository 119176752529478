import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";
import AppError from "../utilComponents/ApiError";

// Array of month names
const monthNames = [
    "December", "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November"
];

export const fetchLocationAdsData = async (locationId) => {
    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }

    try {
        const { data } = await axios.get(`${ENDPOINTS.ADS_SERVICE_BASE_URL}?locationId=${locationId}`, { headers: header });

        const convertedData = {
            ...data,
            adsData: data.adsData.map(ad => ({
                ...ad,
                month: monthNames[ad.month - 1] // Mapping month number to name
            }))
        };

        return convertedData;
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch Ads data");
    }
}