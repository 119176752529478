import React, { useState, useEffect } from 'react';
import { notify } from '../utilComponents/Notification';
import { fetchLocationAdsData } from '../apis/adsCardApi';

const Ads = ({ locationId }) => {
    const [locationAdsData, setLocationAdsData] = useState([]);

    useEffect(() => {
        const getAdsData = async () => {
            try {
                const locationAdsData = await fetchLocationAdsData(locationId); // Fetch contact details from your API
                setLocationAdsData(locationAdsData.adsData);
            } catch (err) {
                notify('error', 'Google Ads Data', err.message);
            }
        };
        getAdsData();
    }, []);

    return (
        <div className="ads-card-content">
            <div className="ads-card-summary">
                Google Ads monthly data
            </div>
            <div className='ads-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Total Impressions</th>
                            <th>Total Clicks</th>
                            <th>Conversion Rate</th>
                            <th>Total Micros</th>
                            <th>CPC</th>
                            <th>CTR</th>
                            <th>Total Conversions</th>
                            <th>Total Campaings</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locationAdsData.map((data) => (
                            <tr key={data.month+data.year+data.location}>
                                <td>{data.month}</td>
                                <td>{data.totalImpressions}</td>
                                <td>{data.totalClicks}</td>
                                <td>{data.conversionRate}</td>
                                <td>{data.totalMicros}</td>
                                <td>{data.cpc}</td>
                                <td>{data.ctr}</td>
                                <td>{data.totalConversions}</td>
                                <td>{data.totalCampaings}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Ads;
