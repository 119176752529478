import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";
import AppError from "../utilComponents/ApiError";

// this will handle all the api functions of the advance filter page

export const getFilteredData = async (filter) => {
    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }
    try {
        const body = {};

        if (filter.active !== "" && filter.active !== undefined) {
            body.active = filter.active;
        }

        if (filter.bookingSoftware !== "" && filter.bookingSoftware !== undefined) {
            body.bookingSoftware = filter.bookingSoftware;
        }

        if (filter.startYear !== "" && filter.startYear !== undefined) {
            body.year_start = Number(filter.startYear);
        }

        if (filter.startMonth !== "" && filter.startMonth !== undefined) {
            body.month_start = Number(filter.startMonth);
        }

        if (filter.revenue?.min !== "" && filter.revenue?.min !== undefined) {
            body.revenue_min = Number(filter.revenue.min);
        }

        if (filter.revenue?.max !== "" && filter.revenue?.max !== undefined) {
            body.revenue_max = Number(filter.revenue.max);
        }

        if (filter.contactCount?.min !== "" && filter.contactCount?.min !== undefined) {
            body.contactCount_min = Number(filter.contactCount.min);
        }

        if (filter.contactCount?.max !== "" && filter.contactCount?.max !== undefined) {
            body.contactCount_max = Number(filter.contactCount.max);
        }

        if (filter.endYear !== "" && filter.endYear !== undefined) {
            body.year_end = Number(filter.endYear);
        }

        if (filter.endMonth !== "" && filter.endMonth !== undefined) {
            body.month_end = Number(filter.endMonth);
        }

        if (filter.postCount?.min !== "" && filter.postCount?.min !== undefined) {
            body.postCount_min = Number(filter.postCount.min);
        }

        if (filter.postCount?.max !== "" && filter.postCount?.max !== undefined) {
            body.postCount_max = Number(filter.postCount.max);
        }

        if (filter.rentalCount?.min !== "" && filter.rentalCount?.min !== undefined) {
            body.no_items_min = Number(filter.rentalCount.min);
        }

        if (filter.rentalCount?.max !== "" && filter.rentalCount?.max !== undefined) {
            body.no_items_max = Number(filter.rentalCount.max);
        }

        if (filter.confirmedLeadCount?.min !== "" && filter.confirmedLeadCount?.min !== undefined) {
            body.confirmedLeadCount_min = Number(filter.confirmedLeadCount.min);
        }

        if (filter.confirmedLeadCount?.max !== "" && filter.confirmedLeadCount?.max !== undefined) {
            body.confirmedLeadCount_max = Number(filter.confirmedLeadCount.max);
        }

        if (filter.amountPaid?.min !== "" && filter.amountPaid?.min !== undefined) {
            body.amount_paid_min = Number(filter.amountPaid.min);
        }

        if (filter.amountPaid?.max !== "" && filter.amountPaid?.max !== undefined) {
            body.amount_paid_max = Number(filter.amountPaid.max);
        }

        if (filter.quoteLeadCount?.min !== "" && filter.quoteLeadCount?.min !== undefined) {
            body.quoteLeadCount_min = Number(filter.quoteLeadCount.min);
        }

        if (filter.quoteLeadCount?.max !== "" && filter.quoteLeadCount?.max !== undefined) {
            body.quoteLeadCount_max = Number(filter.quoteLeadCount.max);
        }

        console.log(body);

        const { data } = await axios.post(`https://service.eventhawkintegration.com/filterview-service/api/filtered-data`, body, { headers: header });
        const rows = data.data.map((row, index) => ({
            ...row,
            entryNumber: index + 1,
            status: row.active ? "Active" : "Deleted"
        }));
        return rows;
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch billing data");
    }
}

export const saveViewToDB = async (body) => {
    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }
    try {
        console.log("BODY: ", body);
        await axios.post(`https://service.eventhawkintegration.com/save-view-service/view/add-filter-view`, body, { headers: header });
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch billing data");
    }
}

export const getViews = async (userId) => {
    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }
    try {
        const { data } = await axios.get(`https://service.eventhawkintegration.com/save-view-service/view/saved-filter-for-user?user_id=${userId}`, { headers: header });
        return data["Saved Filter"];
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch billing data");
    }
}

export const updateViewToDB = async (body) => {
    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }
    try {
        console.log("BODY: ", body);
        await axios.put(`https://service.eventhawkintegration.com/save-view-service/view/update-saved-filer`, body, { headers: header });
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch billing data");
    }
}