import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";

const FilterDisplay = ({ data, viewName }) => {

    const [selectedColumns, setSelectedColumns] = useState([
        'entryNumber',
        'businessName',
        'businessEmail',
        'bookingSoftware',
        'action'
    ]);

    const allColumns = [
        { label: '#', field: 'entryNumber', sort: false },
        { label: 'LocationId', field: 'LocationId', sort: false },
        { label: "Business Name", field: "businessName", sort: false, width: 150 },
        { label: "Email", field: "businessEmail", sort: false, width: 150 },
        { label: "Phone", field: "businessPhone", sort: false },
        { label: "City", field: "city", width: 150 },
        { label: "Account Status", field: "status", width: 150 },
        { label: "SaaS Status", field: "saas", width: 150 },
        { label: "Stripe ID", field: "stripCustomerId", width: 150 },
        { label: "Booking Software", field: "bookingSoftware", width: 150, sort: false },
        { label: "Account Creation Date", field: "accountCreationDate", width: 150, sort: false },
        { label: "Last Update", field: "updatedAt", width: 150 }
    ];

    const fixedColumns = [
        'entryNumber',
        'businessName',
        'businessEmail',
        'bookingSoftware',
    ];

    const handleColumnChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedColumns([...selectedColumns, value]);
        } else {
            setSelectedColumns(selectedColumns.filter(column => column !== value));
        }
    };

    const displayedColumns = allColumns.filter(column => selectedColumns.includes(column.field));

    return (
        <div className='location-table-container'>
            <div className='table-header-container'>
                <h2 className='table-header'>{viewName ? viewName : "Filtered Data"}</h2>
                <div className='column-selection'>
                    <div className="dropdown">
                        <button className="dropbtn">
                            Columns <span className="arrow-down">▼</span>
                        </button>
                        <div className="dropdown-content">
                            {allColumns.map((column, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        value={column.field}
                                        checked={selectedColumns.includes(column.field)}
                                        onChange={handleColumnChange}
                                        disabled={fixedColumns.includes(column.field)}
                                    />
                                    <label>{column.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='custom-data-table'>
                <MDBDataTable
                    striped
                    bordered
                    hover
                    fixed
                    data={{ columns: displayedColumns, rows: data }}
                    className="table-responsive"
                />
            </div>
        </div>
    );
};

export default FilterDisplay;
