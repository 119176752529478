import React, { useEffect, useState } from "react";

const FilterInput = ({ filters, onFilterChange, fetchData, clearFilters, saveView, updateView }) => {

    const [showAdvanced, setShowAdvanced] = useState(false);

    const toggleAdvancedFilters = () => {
        setShowAdvanced(!showAdvanced);
    };

    const bookingSoftwareOptions = ["IO", "ERS", "Event Hawk"];
    const accountStatusOptions = ["All", "active", "deleted"];
    const saasStatusOptions = ["All", "activated", "not_activated", "none"];

    const months = Array.from({ length: 12 }, (_, i) => i + 1); // 1 to 12 for months
    const years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i); // Last 20 years

    return (
        <div className="filter-input-container">

            {/* Date Picker */}
            <div className="filter-row">
                <div className="filter-item">
                    <label>Start Month:</label>
                    <select
                        value={filters.startMonth || ""}
                        onChange={(e) => onFilterChange("startMonth", e.target.value)}
                    >
                        <option value="">Select Month</option>
                        {months.map((month, index) => (
                            <option key={index} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="filter-item">
                    <label>Start Year:</label>
                    <select
                        value={filters.startYear || ""}
                        onChange={(e) => onFilterChange("startYear", e.target.value)}
                    >
                        <option value="">Select Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="filter-item">
                    <label>End Month:</label>
                    <select
                        value={filters.endMonth || ""}
                        onChange={(e) => onFilterChange("endMonth", e.target.value)}
                    >
                        <option value="">Select Month</option>
                        {months.map((month, index) => (
                            <option key={index} value={month}>
                                {month}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="filter-item">
                    <label>End Year:</label>
                    <select
                        value={filters.endYear || ""}
                        onChange={(e) => onFilterChange("endYear", e.target.value)}
                    >
                        <option value="">Select Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Date Dependent Filters */}
            <div className="filter-row">

                <div className="filter-item">
                    <label>Revenue :</label>
                    <div>
                        <input
                            type="number"
                            placeholder="Min"
                            value={filters.revenue.min}
                            onChange={(e) =>
                                onFilterChange("revenue", {
                                    ...filters.revenue,
                                    min: e.target.value,
                                })
                            }
                        />
                        <input
                            type="number"
                            placeholder="Max"
                            value={filters.revenue.max}
                            onChange={(e) =>
                                onFilterChange("revenue", {
                                    ...filters.revenue,
                                    max: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>

                <div className="filter-item">
                    <label>Rental Count:</label>
                    <div>
                        <input
                            type="number"
                            placeholder="Min"
                            value={filters.rentalCount.min}
                            onChange={(e) =>
                                onFilterChange("rentalCount", {
                                    ...filters.rentalCount,
                                    min: e.target.value,
                                })
                            }
                        />
                        <input
                            type="number"
                            placeholder="Max"
                            value={filters.rentalCount.max}
                            onChange={(e) =>
                                onFilterChange("rentalCount", {
                                    ...filters.rentalCount,
                                    max: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>

                <div className="filter-item">
                    <label>Response Count:</label>
                    <div>
                        <input
                            type="number"
                            placeholder="Min"
                            value={filters.responseCount.min}
                            onChange={(e) =>
                                onFilterChange("responseCount", {
                                    ...filters.responseCount,
                                    min: e.target.value,
                                })
                            }
                        />
                        <input
                            type="number"
                            placeholder="Max"
                            value={filters.responseCount.max}
                            onChange={(e) =>
                                onFilterChange("responseCount", {
                                    ...filters.responseCount,
                                    max: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>

                <div className="filter-item">
                    <label>Post Count:</label>
                    <div>
                        <input
                            type="number"
                            placeholder="Min"
                            value={filters.postCount.min}
                            onChange={(e) =>
                                onFilterChange("postCount", { ...filters.postCount, min: e.target.value })
                            }
                        />
                        <input
                            type="number"
                            placeholder="Max"
                            value={filters.postCount.max}
                            onChange={(e) =>
                                onFilterChange("postCount", { ...filters.postCount, max: e.target.value })
                            }
                        />
                    </div>
                </div>

            </div>

            {/* Toggle Advanced Filters */}
            <p className="toggle-text" onClick={toggleAdvancedFilters}>
                {showAdvanced ? "Hide More Filters ▲" : "Show More Filters ▼"}
            </p>

            {showAdvanced && (
                <div className="advanced-filters">
                    <div className="filter-row">
                        <div className="filter-item">
                            <label>Booking Software:</label>
                            <select
                                value={filters.bookingSoftware}
                                onChange={(e) => onFilterChange("bookingSoftware", e.target.value)}
                            >
                                <option value="">Select</option>
                                {bookingSoftwareOptions.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>

                        <div className="filter-item">
                            <label>Account Status:</label>
                            <select
                                value={filters.active}
                                onChange={(e) => onFilterChange("active", e.target.value)}
                            >
                                <option value="">Select</option>
                                {accountStatusOptions.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>

                        <div className="filter-item">
                            <label>SaaS Status:</label>
                            <select
                                value={filters.saasStatus}
                                onChange={(e) => onFilterChange("saasStatus", e.target.value)}
                            >
                                <option value="">Select</option>
                                {saasStatusOptions.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="filter-row">
                        <div className="filter-item">
                            <label>Contact Count:</label>
                            <div>
                                <input
                                    type="number"
                                    placeholder="Min"
                                    value={filters.contactCount.min}
                                    onChange={(e) =>
                                        onFilterChange("contactCount", {
                                            ...filters.contactCount,
                                            min: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    type="number"
                                    placeholder="Max"
                                    value={filters.contactCount.max}
                                    onChange={(e) =>
                                        onFilterChange("contactCount", {
                                            ...filters.contactCount,
                                            max: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="filter-item">
                            <label>Confirmed Lead Count :</label>
                            <div>
                                <input
                                    type="number"
                                    placeholder="Min"
                                    value={filters.confirmedLeadCount.min}
                                    onChange={(e) =>
                                        onFilterChange("confirmedLeadCount", {
                                            ...filters.confirmedLeadCount,
                                            min: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    type="number"
                                    placeholder="Max"
                                    value={filters.confirmedLeadCount.max}
                                    onChange={(e) =>
                                        onFilterChange("confirmedLeadCount", {
                                            ...filters.confirmedLeadCount,
                                            max: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div className="filter-item">
                            <label>Quote Lead Count :</label>
                            <div>
                                <input
                                    type="number"
                                    placeholder="Min"
                                    value={filters.quoteLeadCount.min}
                                    onChange={(e) =>
                                        onFilterChange("quoteLeadCount", {
                                            ...filters.quoteLeadCount,
                                            min: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    type="number"
                                    placeholder="Max"
                                    value={filters.quoteLeadCount.max}
                                    onChange={(e) =>
                                        onFilterChange("quoteLeadCount", {
                                            ...filters.quoteLeadCount,
                                            max: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>

                        <div className="filter-item">
                            <label>Amount Paid :</label>
                            <div>
                                <input
                                    type="number"
                                    placeholder="Min"
                                    value={filters.amountPaid.min}
                                    onChange={(e) =>
                                        onFilterChange("amountPaid", {
                                            ...filters.amountPaid,
                                            min: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    type="number"
                                    placeholder="Max"
                                    value={filters.amountPaid.max}
                                    onChange={(e) =>
                                        onFilterChange("amountPaid", {
                                            ...filters.amountPaid,
                                            max: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {/* Actions */}
            <div className="filter-actions">
                <button onClick={fetchData}>Apply Filters</button>
                <button onClick={clearFilters}>Clear Filters</button>
                <button onClick={updateView}>Save View</button>
                <button onClick={saveView}>Save As New</button>
            </div>
        </div>
    );
};

export default FilterInput;
