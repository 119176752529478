import axios from "axios";
import { ENDPOINTS, TOKENS } from "../utilComponents/constants";
import AppError from "../utilComponents/ApiError";

export const fetchChargesData = async (locationId) => {

    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }

    try {
        const { data } = await axios.get(`${ENDPOINTS.DASHBOARD_BASE_URL}/dashboard/location/billing-recent-charges?locationId=${locationId}`, { headers: header });
        return data;
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch billing data");
    }
}

export const fetchSubscriptionData = async (locationId) => {

    const header = {
        "Authorization": `Bearer ${TOKENS.DASHBOARD_AUTH_TOKEN}`
    }

    try {
        const { data } = await axios.get(`${ENDPOINTS.DASHBOARD_BASE_URL}/dashboard/location/billing-subscriptions?locationId=${locationId}`, { headers: header });
        return data;
    } catch (error) {
        console.log("ERROR: ", error);
        throw new AppError("Cannot fetch subscription data");
    }
}