import React, { useEffect, useState } from "react";
import FilterInput from "./FilterInput.jsx";
import FilterDisplay from "./FilterDisplay.jsx";
import { getFilteredData, saveViewToDB, updateViewToDB, getViews } from "../apis/advanceFilterApi.js";
import Views from "./Views.jsx";

const AdvancedFilters = () => {
    const [filters, setFilters] = useState({
        rentalCount: { min: "", max: "" },
        bookingSoftware: "",
        revenue: { min: "", max: "" },
        responseCount: { min: "", max: "" },
        contactCount: { min: "", max: "" },
        postCount: { min: "", max: "" },
        confirmedLeadCount: { min: "", max: "" },
        quoteLeadCount: { min: "", max: "" },
        amountPaid: { min: "", max: "" },
        startDate: "",
        endDate: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        active: "",
    });

    const [view, setView] = useState(null);
    const [savedViews, setSavedViews] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchViews();
    }, []);

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const uploadFilter = (view) => {
        setFilters(view.filters);
        setView(view);
        fetchData();
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            const data = await getFilteredData(filters);
            setFilteredData(data);
        } catch (err) {
            // setError("Failed to fetch data. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const clearFilters = () => {
        setFilters({
            rentalCount: { min: "", max: "" },
            bookingSoftware: "",
            responseCount: { min: "", max: "" },
            contactCount: { min: "", max: "" },
            startDate: "",
            endDate: "",
        });
        setFilteredData([]);
        setView(null);
        setLoading(true);
    };

    const saveView = async () => {
        const user = JSON.parse(localStorage.getItem("user")); // Retrieve user from local storage

        // Prompt user for view name
        const searchViewName = prompt("Enter a name for your saved search:");
        if (!searchViewName) {
            alert("View name is required to save the search.");
            return;
        }

        const viewData = {
            user_id: user.email,
            user_name: user.name,
            search_view_name: searchViewName,
            filters: filters,
        };

        try {
            await saveViewToDB(viewData);
            alert("View saved successfully!");
        } catch (error) {
            console.error("Failed to save view:", error);
        }
    };

    const updateView = async () => {
        const viewData = {
            ...view,
            filters: filters
        };

        try {
            await updateViewToDB(viewData);
            fetchViews();
            alert("View saved successfully!");
        } catch (error) {
            console.error("Failed to save view:", error);
        }
    }

    const fetchViews = async () => {
        const user = JSON.parse(localStorage.getItem("user"));

        try {
            const views = await getViews(user.email); // Call the API function to fetch views
            setSavedViews(views);
        } catch (err) {
            console.error('Failed to fetch saved views:', err);
        }
    }

    return (
        <div className="homepage-page-container">
            <FilterInput
                filters={filters}
                onFilterChange={handleFilterChange}
                fetchData={fetchData}
                clearFilters={clearFilters}
                saveView={saveView}
                updateView={updateView}
            />
            <Views
                uploadFilter={uploadFilter}
                savedViews={savedViews} />
            {!loading && <FilterDisplay
                data={filteredData}
                viewName={view ? view.searchViewName : null} />}
        </div>
    );
};

export default AdvancedFilters;
