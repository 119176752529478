import React, { useState } from "react";

const Views = ({ uploadFilter, savedViews }) => {
    const [selectedViewId, setSelectedViewId] = useState("");

    const handleViewChange = (event) => {
        console.log(event.target.value);
        const selectedView = savedViews.find(view => String(view.search_id) === event.target.value);
        setSelectedViewId(event.target.value);
        if (selectedView) {
            uploadFilter(selectedView);
        }
    };

    return (
        <div className="views-dropdown-container">
            <label htmlFor="viewsDropdown">Select an Existing View:</label>
            <select
                id="viewsDropdown"
                value={selectedViewId}
                onChange={handleViewChange}
            >
                <option value="">-- Choose a view --</option>
                {savedViews.map(view => (
                    <option key={view.search_id} value={view.search_id}>
                        {view.search_view_name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Views;
