
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Homepage from './pages/Homepage';
import LocationPage from './pages/LocationPage';
import Workflows from './pages/Workflows';
import Navbar from './Navbar';
import LoginPage from './pages/LoginPage';
import AdvancedFilters from './pages/AdvancedFilters';
import Notification from './utilComponents/Notification';
import ProtectedRoute from './utilComponents/ProtectedRouter';

function App() {

    return (
            <div>
                <Router>
                    <Helmet>
                        <title>EH Dashboard</title>
                    </Helmet>
                    <ConditionalNavbar />
                    <div className='content'>
                        <Routes>
                            <Route exact path='/login' element={<LoginPage />} />

                            <Route exact path='/' element={<ProtectedRoute>
                                <Homepage />
                            </ProtectedRoute>} />

                            <Route exact path='/location/:locationId' element={<ProtectedRoute>
                                <LocationPage />
                            </ProtectedRoute>} />

                            <Route exact path='/workflows' element={<ProtectedRoute>
                                <Workflows />
                            </ProtectedRoute>} />

                            <Route exact path='/filters' element={<ProtectedRoute>
                                <AdvancedFilters />
                            </ProtectedRoute>} />

                        </Routes>
                    </div>
                    <Notification />
                </Router>
            </div>
    )
}

// Component to conditionally show Navbar
const ConditionalNavbar = () => {
    const location = useLocation(); // Get the current path
    // Only show Navbar if the current path is not '/login'
    return location.pathname !== '/login' ? <Navbar /> : null;
};

export default App;